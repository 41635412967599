const templateVariables = {
  name: 'revelation',
  locate: 'cn',
  folderUse: [
    'main',
    'block_1',
    'block_2',
    'block_3',
    'float_right',
    'navigation',
    'footer',
    'popup'
  ],
  generateFile: ['index'],
  H5: false,
  scaleWidthPC: 1920,
  scaleWidthMB: 750,
};

module.exports = templateVariables;