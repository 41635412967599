var welcome = $(".input-region").eq(0).val();
var language = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, "$1");
var d = new Date();
d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
var expires = "expires=" + d.toUTCString();

jQuery(document).ready(function () {
	// $('.currentInput').change(function () {
	// 	if ($(this).is(':checked')) {
	// 		$('#float_right').css('top', 46 + '%')
	// 	} else {
	// 		$('#float_right').css('top', 30 + '%')

	// 	}
	// });

	// const templateHTML = ` 
	// 	<div class="language">
	// 		<input class="currentInput" type="checkbox" name="" id="language" data-region="ph">
	// 		<label for="language" class="floattop__item floattop__item--language current currentLabel">EN</label>
	//     	<div class="language__list choose-language">
	// 	    	<input type="hidden" id="input-region" value="ph">
	// 	        <ul class="dropdown-content">
	// 	            <li class="language__item active"><a class="region" href="#" data-region="vn"><span class="choose desktop"></span>VN</a></li>
	// 	            <li class="language__item"><a class="region" href="#" data-region="th"><span class="choose desktop"></span>TH</a></li>
	// 	            <li class="language__item"><a class="region" href="#" data-region="id"><span class="choose desktop"></span>ID</a></li>
	// 	            <li class="language__item"><a class="region" href="#" data-region="td"><span class="choose desktop"></span>TWHK</a></li>
	// 	            <li class="language__item"><a class="region" href="#" data-region="ph"><span class="choose desktop"></span>EN</a></li>
	// 	        </ul>
	//     	</div>
	// 	</div>
	// `;
	// if ($('body').data('device-type') === 'desktop') {
	// 	$('.genLanguage').append(templateHTML);
	// } else {

	// };

	$(".choose-language .region").removeClass("languages__current");
	console.log("language activate");
	if (language.length == 0) {
		document.cookie = "language=" + welcome + ";" + expires + "; path=/";
		$(".choose-language .region[data-language=" + welcome + "]").addClass("active");
		renewChooseLanguage();
	} else {
		renewChooseLanguage();
	}
	$(".dropdown-content .region").on("click", function (e) {
		e.preventDefault();
		welcome = $(this).data("region");
		document.cookie = "language=" + welcome + ";" + expires + "; path=/";
		if (!$(this).hasClass("languages__current")) {
			document.location.href = `/${welcome}/foxspirit-3-4`;
		}
	});
});

function renewChooseLanguage() {
	var x = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	var chosenNationText = $(".choose-language .region[data-region=" + x + "]")
		.eq(0)
		.text();
	var chosenLang = x;
	let keyName = {
		en: "English",
		vn: 'Tiếng Việt',
		th: 'ไทย',
		id: 'Indonesia',
		cn: '中文',
	};
	$(".current").html(keyName[chosenLang]);
	$(".currentInput").attr("data-region", chosenLang);
	$(".choose-language .region[data-region=" + x + "]").parent().addClass("active");
}
