// import 'animate.css';
// import Swiper, {Navigation} from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
import './scss/block_1.scss';



class Core {
    constructor() {
        this.currentTimeout = null;
    };
    wait(timeout) {
        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        };
        return new Promise(resolve => {
            this.currentTimeout = setTimeout(resolve, timeout);
        });
    };
};

window.addEventListener("load", function () {

});
