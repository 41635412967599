// import clearLoad from "./scaleLoading.js"; 
// SCALE PLATFORM 
// | Especially use for Web Platform 3.0 / 2022
// | Although WP3.2.3 is preventing non-responsive design, there still have some
// | unexpected input PSD from outsource, out-dated designers.

import configJSON from '../../../config.js';

$(window).on("load", function () {
    if (!configJSON.H5 || !configJSON.layerLoading) return;
    let layerRotateDiv = document.createElement("div");
    layerRotateDiv.className = "rotate-phone";

    let imageElement = document.createElement("img");
    imageElement.src = "https://global-mainsite.mto.zing.vn/products/metalslug/item-loading/layerRotate.gif";

    layerRotateDiv.appendChild(imageElement);
    document.body.appendChild(layerRotateDiv);
});


const { observe } = require("./setup");

var config = {
    widthTriggerScale: 9999,
    widthDesktopSafe: 1620,
    widthDesktop: configJSON.scaleWidthPC,
    widthMobile: configJSON.scaleWidthMB,
    heightDesktop: 1000,
    heightMobile: 1000,
    func: {
        isMobile: function () {
            var device = {
                width: $(window).innerWidth(),
                height: $(window).innerHeight()
            };

            return ((device.width <= config.widthMobile));
        }
    }
}

$.fn.scalePlatform = function (options) {
    var defaults = {
        obj: $(this),
        designSafe: {
            // if width of device smaller safe zone, then init scale. 
            desktop: config.widthTriggerScale,
            mobile: config.widthTriggerScale
        },
        designWidth: {
            desktop: config.widthDesktop,
            mobile: config.widthMobile
        },
        designHeight: {
            desktop: config.heightDesktop,
            mobile: config.heightMobile
        },
        mode: '', //scaleForWidth
        elScale: {
            desktop: ".scaleDesktop",
            mobile: ".scaleMobile"
        },
        dataScale: "data-scale-ratio",
        dataDevice: "data-device-type",
        dataDisplay: {
            desktop: "data-desktop-display",
            mobile: "data-mobile-display",
        },
        dataOrigin: {
            desktop: "data-desktop-origin",
            mobile: "data-mobile-origin",
        },
        rescaleForParent: true,
        deviceHeightStyleTag: {
            fix: "fixDeviceHeight",
            max: "maxDeviceHeight"
        },
        deviceHeightStyleVar: "--sr-device-height",
        deviceScaleRatioStyleVar: "--sr-scale-ratio"
    }
    var settings = $.extend(defaults, options);


    var setToDefault = function () {
        $(settings.obj).attr("style", "");
        $(settings.obj).parent().attr("style", "");
        $(settings.elScale.desktop + " , " + settings.elScale.mobile).each(function () {
            $(this).attr("style", "");
        });
        $(settings.obj).parent().attr(settings.dataScale, 1);

        // console.log('set to default');
    }();

    var device = {
        width: $(window).outerWidth(),
        height: $(window).innerHeight()
    };


    // console.log(device);

    var isMobile = (width = device.width, height = device.height) => ((width <= config.widthMobile));

    var scaleRatio, marginLeft;
    if (configJSON.H5) {
        if (!config.func.isMobile()) {
            $('.rotate-phone').css({
                opacity: 0,
                zIndex: -10000,
            });
        } else {
            $(".rotate-phone").css({
                opacity: 1,
                zIndex: 10000,
            });
        };
        if (device.width / device.height > 2) {
            scaleRatio = isMobile()
                ? device.height / settings.designHeight.mobile
                : device.height / settings.designHeight.desktop,
                marginLeft = 0;

        } else {
            scaleRatio = isMobile()
                ? device.width / settings.designWidth.mobile
                : device.width / settings.designWidth.desktop,
                marginLeft = 0;

        };
    } else {
        scaleRatio = (isMobile()) ?
            device.width / settings.designWidth.mobile :
            device.width / settings.designWidth.desktop,
            marginLeft = 0;
    };

    if (settings.deviceHeightStyleTag != false && $("#deviceHeightStyleTag").length < 1) {
        var rescaledHeight = "calc(100vh * " + 1 / scaleRatio + ")";
        var styleTag = $(`
            <style id="deviceHeightStyleTag">
                .${settings.deviceHeightStyleTag.max} {
                    max-height: ${rescaledHeight}
                }
                .${settings.deviceHeightStyleTag.fix} {
                    height: ${rescaledHeight}
                }
            </style>
        `);
        $('html > head').append(styleTag);
    }

    if ((settings.deviceHeightStyleVar != false) && ($("#deviceHeightStyleVar").length < 1)) {
        var rescaledHeight = "calc(100vh * " + 1 / scaleRatio + ")";
        var styleTag = $(`
            <style id="deviceHeightStyleVar">
                :root {
                    ${settings.deviceHeightStyleVar}: ${rescaledHeight};
                    ${settings.deviceScaleRatioStyleVar}: ${scaleRatio}
                }
            </style>
        `);
        $('html > head').append(styleTag);
    }

    $(settings.elScale.desktop + " , " + settings.elScale.mobile).each(function () {

        let isMobile = config.func.isMobile();

        // console.log(isMobile);

        var scaleOrigin = (isMobile) => {
            if (isMobile && ($(this).attr(settings.dataOrigin.mobile) != undefined)) {
                return $(this).attr(settings.dataOrigin.mobile);
            } else if ($(this).attr(settings.dataOrigin.desktop) != undefined) {
                return $(this).attr(settings.dataOrigin.desktop);
            }
            return 'top left';
        }

        var display = (isMobile) => {
            console.log(isMobile);
            if (isMobile && ($(this).attr(settings.dataDisplay.mobile) != undefined)) {
                return $(this).attr(settings.dataDisplay.mobile);
            } else if ($(this).attr(settings.dataDisplay.desktop) != undefined) {
                return $(this).attr(settings.dataDisplay.desktop);
            }
            return 'block';
        }

        // $(this).css({
        //     transform: "scale(" + scaleRatio + ")",
        //     marginLeft: marginLeft + "px",
        //     transformOrigin: scaleOrigin 
        // });

        // console.log(this.className, scaleOrigin(), scaleRatio);

        // Edit Load Icon Scale
        if ((isMobile && $(this).hasClass(settings.elScale.mobile.replace(".", ""))) ||
            (!isMobile && $(this).hasClass(settings.elScale.desktop.replace(".", "")))) {

            let transformCSS;
            if (configJSON.H5) {
                // $('#wrapper, .outer').css("position", "fixed");
                $('#wrapper, .outer').css("left", "50%");
                $('.outer').css("overflow", "hidden");
                const fullHeight = 1000 + (1000 - (1000 * (scaleRatio)));
                $('.outer').css("height", fullHeight);
                transformCSS = "scale(" + scaleRatio + ") translateX(-50%)";
            } else {
                transformCSS = "scale(" + scaleRatio + ")";
            };

            $(this).css({
                transform: transformCSS,
                marginLeft: marginLeft + "px",
                transformOrigin: scaleOrigin(isMobile),
                // display: display
            });
            if (isMobile) {
                $('#wrapper, .outer, .floating').css("width", configJSON.scaleWidthMB + 'px');
            } else {
                console.log('hi');
                $('#wrapper, .outer, .floating').css("width", configJSON.scaleWidthPC + 'px');
            }
            if (document.querySelector('.layer')) {
                document.querySelector('.lds-dual-ring').style.opacity = '0';
                document.querySelector('.layer').style.opacity = '0';
                document.querySelector('.layer').style.visibility = 'hidden';

                // if (window.innerWidth >= 768) {
                //   $('.layer .lds-dual-ring').css('display','block');
                //   $('.layer .rotate').css('display','none');
                // } else {
                //   $('.layer').css('opacity','1');
                //   $('.layer').css('visibility','visible');
                //   $('.layer .lds-dual-ring').css('display','none');
                //   $('.layer .rotate').css('display','block');
                // }
            };
            // observe();

        }
    });

    if (settings.rescaleForParent) {
        var obj = {
            height: settings.obj.outerHeight()
        }
        var scaleHeight = obj.height * scaleRatio;
        // console.log(scaleHeight, obj.height, scaleRatio);
        $(settings.obj).parent().css({

            height: scaleHeight + "px",
            // width: device.width+"px"
        })
    }

    $(settings.obj).parent().attr(settings.dataScale, scaleRatio);
    $(settings.obj).parent().attr(settings.dataDevice, (isMobile()) ? "mobile" : "desktop");
}