// import 'animate.css';
// import Swiper, {Navigation} from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
import './scss/popup.scss';

window.addEventListener("load", function () {
    const currentPopup = $('.readscroll');
   
    currentPopup.find('.text').on("scrollend", function() {
        console.log('a');
        $('.readscroll.active').find('.btn2').removeClass('needScroll');
    });

  


});

