// Scale
window.addEventListener("load", function () {
  Array.from($("#wrapper,.outer, .floating")).forEach(function (item) {
    item.classList.add("scaleDesktop", "scaleMobile");
  });
  $("#wrapper").scalePlatform();
  $(window).on("resize", function () {
    $("#wrapper").scalePlatform();
  });
});

// Target click
var target;
$(".clickActive").on("click", function (e) {
  target = $(this).data("target");
  $(target).toggleClass("active");
  e.preventDefault();
  return false;
});
if ($(".popup .background").length > 0) {
  $(".popup .background").each(function () {
    $(this).on("click", function () {
      $(this).parent().removeClass("active");
    });
  });
}
$("body").on("click", ".close-popup", function (event) {
  //$(".popup").removeClass("active");
  // $(this).parent().parent().removeClass("active");
  $(this).parents('.popup').removeClass("active");
  return false;
});

$('.clickIframe').on("click", function (e) {
  const linkIframe = $(this).data("link");
  const targetIframe = $(this).data("iframe");
  $(targetIframe).attr('src', linkIframe);
  e.preventDefault();
  return false;
});

// document.querySelector('.btn-nolayer').addEventListener("click", function(e) {
//   document.querySelector('#popup_reward').classList.remove("active");
// });


// export function observe() {
//   const observer = new IntersectionObserver((entries) => {
//     entries.forEach(entry => {
//       if (entry.intersectionRatio >= 0.7) {
//         const elTarget = $(entry.target);
//         if (!elTarget.hasClass('viewing')) {
//           $('#wrapper section').add('#float_left ul li a').removeClass('viewing active');
//           elTarget.addClass('viewing');
//           const elNavTarget = $('#float_left').find(`a[href="#${elTarget.attr('id')}"]`);
//           elNavTarget.addClass('active');
//         };
//       }
//     });
//   }, {
//     threshold: [0, 0.7, 1]
//   });

//   const targetElements = document.querySelectorAll('#block_1,#block_2, #block_3, #block_4');
//   targetElements.forEach(element => observer.observe(element));

// }

export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

// Locate
// var locate = document.querySelector('body').classList[0];
// var url = `./assets/${locate}`;
// // var url = `https://global-mainsite.mto.zing.vn/products/metalslug/landing/2023-muachung/dist/assets/images/locate/${locate}`;
// Array.from(document.querySelectorAll('img')).forEach(function(item) {
//   if (item.dataset.js) {
//     item.src = `${url}/${item.dataset.js}`
//   };
// });