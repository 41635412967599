import './scss/block_2.scss';
// import Swiper from 'swiper/bundle';
// // import styles bundle
// import 'swiper/css/bundle';


class Core {
  constructor() {
    this.currentTimeout = null;
  };
  wait(timeout) {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    };
    return new Promise(resolve => {
      this.currentTimeout = setTimeout(resolve, timeout);
    });
  };
};

window.addEventListener("load", function () {


});