// import 'animate.css';
// import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

import './scss/block_3.scss';
// import '../main/js/tweenmax.js';
// import dndPromotion from '../main/js/promotion2.js';

window.addEventListener("load", function () {


});

